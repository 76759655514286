<template>
	<div
		class="obi-floating-input form-floating is-invalid"
		:class="[{ 'mb-3': !compact }, wrapperClass]"
	>
		<div class="obi-floating-input-icon" v-if="icon">
			<component :is="icon" />
		</div>
		<input
			:type="type"
			v-bind="attrs"
			class="form-control"
			:id="id || defaultId"
			:class="{ 'is-invalid': errors.length, 'bg-transparent': transparent }"
			v-model="inputValue"
		/>
		<label
			:for="id || defaultId"
			v-html="label"
			v-if="label"
			:class="labelClass"
		/>
		<div class="invalid-feedback" v-if="errors.length">
			<div
				class="invalid-feedback-line"
				v-for="(error, index) in errors"
				:key="index"
				v-html="error"
			></div>
		</div>
	</div>
</template>

<script>
import uuid from "uuidjs";
import { get, keys, omit } from "lodash";

export default {
	name: "ObiFloatingInput",
	props: {
		value: {},
		id: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: "text",
		},
		label: {
			type: String,
			default: null,
		},
		icon: {
			type: Object,
			default: null,
		},
		wrapperClass: {
			type: String,
			default: null,
		},
		labelClass: {
			type: String,
			default: null,
		},
		compact: {
			type: Boolean,
			default: false,
		},
		errors: {
			type: Array,
		},
		transparent: {
			type: Boolean,
			default: false,
		},
	},
	beforeMount() {
		this.defaultId = get(uuid.genV4(), "hexNoDelim");
	},
	computed: {
		attrs() {
			return omit(this.$attrs, keys(this.$props));
		},
		inputValue: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit("input", val);
			},
		},
	},
	data() {
		return {
			defaultId: null,
		};
	},
};
</script>

<style lang="scss" scoped>
.obi-floating-input {
	.invalid-feedback {
		text-align: left;
	}

	.obi-floating-input-icon {
		top: 0;
		left: 0;
		width: 3rem;
		display: flex;
		height: 3.6rem;
		position: absolute;
		align-items: center;
		justify-content: center;

		~ .form-control {
			padding-left: 3rem;

			&:focus ~ label,
			&:not(:placeholder-shown) ~ label {
				transform: scale(0.85) translateY(-0.5rem) translateX(0.5rem);
			}
		}

		~ .form-select {
			padding-left: 3rem;

			~ label {
				transform: scale(0.85) translateY(-0.5rem) translateX(0.5rem);
			}
		}

		~ label {
			padding-left: 3rem;
		}
	}
}
</style>
